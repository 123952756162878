import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import Logger from '../../shared/helpers/logger';
import { RestSecurityService } from '../security/rest-security.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private encryptedMethodsList = ['POST', 'PUT', 'DELETE'];

  constructor(private restSecurityService: RestSecurityService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isEncryptedMethod(httpRequest.method) && httpRequest.body !== null) {
      Logger.log('ApiInterceptor intercept isEncryptedMethod', JSON.stringify(httpRequest));
      const data = this.restSecurityService.encrypt(JSON.stringify(httpRequest.body));
      httpRequest = httpRequest.clone({ body: { encryptedData: data } });
    }
    return next.handle(httpRequest);
  }

  isEncryptedMethod(method: string): boolean {
    return this.encryptedMethodsList.includes(method);
  }
}

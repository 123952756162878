import {Injectable} from "@angular/core";
import {User} from "../models";

@Injectable({providedIn: 'root'})
export class RolesService {
    ROLE_ADMIN: string = "ROLE_ADMIN";
    ROLE_OWNER: string = "ROLE_OWNER";
    ROLE_SALES: string = "ROLE_SALES";
    ROLE_SALES_LEAD: string = "ROLE_SALES_LEAD";
    ROLE_SUPPORT: string = "ROLE_SUPPORT";
    ROLE_FINANCIAL: string = "ROLE_FINANCIAL";

    hasAnyOfRoles(user: User, roles: String[]) {
        for (var roleToCheck of roles) {
            if (user?.roles?.indexOf(roleToCheck) !== -1) {
                return true
            }
        }

        return false

    }

    getPrettyName(role: string) {
        if (role == "ROLE_ADMIN") {
            return "Admin"
        } else if (role == 'ROLE_OWNER') {
            return "Owner"
        } else if (role == 'ROLE_SALES') {
            return "Sales";
        } else if (role == 'ROLE_SALES_LEAD') {
            return "Sales lead"
        } else if (role == 'ROLE_SUPPORT') {
            return "Support"
        } else if (role == 'ROLE_FINANCIAL') {
            return "Financial"
        } else {
            return role
        }
    }

    getPrettyNameForRoles(roles: String[]) {
        let result: String[] = []
        if (roles == null) {
            return
        }

        for (var role of roles) {
            result.push(this.getPrettyName(role.valueOf()))
        }

        return result
    }

    getRoleColorClass(role: string) {
        if (role == "ROLE_ADMIN") {
            return "admin-color"
        } else if (role == 'ROLE_OWNER') {
            return "owner-color"
        } else if (role == 'ROLE_SALES') {
            return "sales-color";
        } else if (role == 'ROLE_SALES_LEAD') {
            return "sales-lead-color"
        } else if (role == 'ROLE_SUPPORT') {
            return "support-color"
        } else if (role == 'ROLE_FINANCIAL') {
            return "financial-color"
        } else {
            return "owner-color"
        }
    }

    getRoleFeather(role: string) {
        if (role == "ROLE_ADMIN") {
            return "settings"
        } else if (role == 'ROLE_OWNER') {
            return "codepen"
        } else if (role == 'ROLE_SALES') {
            return "user";
        } else if (role == 'ROLE_SALES_LEAD') {
            return "award"
        } else if (role == 'ROLE_SUPPORT') {
            return "database"
        } else if (role == 'ROLE_FINANCIAL') {
            return "edit-2"
        } else {
            return "activity"
        }
    }

}

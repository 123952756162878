import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class RestSecurityService {
  private keySize: number;
  private iterationCount: number;
  private iv: string;
  private salt: string;
  private passPhrase: string;

  constructor() {
    this.keySize = 128 / 32;
    this.iterationCount = 1000;
    this.iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    this.salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
    this.passPhrase = environment.passPhrase;
  }

  private generateKey(salt: string, passPhrase: string): CryptoJS.lib.WordArray {
    return CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), { keySize: this.keySize, iterations: this.iterationCount });
  }

  public encrypt(plainText: string): string {
    const key = this.generateKey(this.salt, this.passPhrase);
    const encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: CryptoJS.enc.Hex.parse(this.iv) });
    return this.iv + '::' + this.salt + '::' + encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  public decrypt(cipherText: string): string {
    const key = this.generateKey(this.salt, this.passPhrase);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText),
    });
    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, { iv: CryptoJS.enc.Hex.parse(this.iv) });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

import { isProduction } from './helper';

export default {
  warn: (...args: any[]) => {
    if (!isProduction()) {
      console.warn(`[logger warning] ${new Date().toString()}`, ...args);
    }
  },
  error: (...args: any[]) => {
    if (!isProduction()) {
      console.error(`[logger error] ${new Date().toString()}`, ...args);
    }
  },
  log: (...args: any[]) => {
    if (!isProduction()) {
      console.log(`[logger] ${new Date().toString()}`, ...args);
    }
  },
};

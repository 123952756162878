import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MerchantUser } from '../models/MerchantUser';
import { CoreConfigService } from '../../../@core/services/config.service';
import {User} from "../models";
import {Router} from "@angular/router";

const TOKEN_KEY = 'authToken';
const USER_KEY = 'currentUser';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {
  public user: User;
  private expirationLength = 365;

  constructor(private cookieService: CookieService, private coreConfigService: CoreConfigService, private router: Router) {
    if (this.cookieService.check(TOKEN_KEY) && this.cookieService.check(USER_KEY)) {
      this.saveToken(this.cookieService.get(TOKEN_KEY));
      window.sessionStorage.setItem(USER_KEY, this.cookieService.get(USER_KEY));
      this.getUser();
    }
  }

  signOut() {
    this.user = undefined;
    window.sessionStorage.clear();
    this.cookieService.deleteAll();
    this.router.navigate(['login']);
  }

  saveCookie(data) {
    this.cookieService.delete(TOKEN_KEY);
    this.cookieService.delete(USER_KEY);
    this.cookieService.set(TOKEN_KEY, data.accessToken, this.expirationLength);
    this.cookieService.set(USER_KEY, JSON.stringify(data), this.expirationLength);
  }

  saveCookieSession(data) {
    this.cookieService.delete(TOKEN_KEY);
    this.cookieService.delete(USER_KEY);
    this.cookieService.set(TOKEN_KEY, data.accessToken);
    this.cookieService.set(USER_KEY, JSON.stringify(data));
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveMerchant(merchantuser) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(merchantuser));
    this.user = JSON.parse(sessionStorage.getItem(USER_KEY));
  }

  public saveUser(user) {
    console.log("saving user:" + JSON.stringify(user))
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user))
    this.user = JSON.parse(sessionStorage.getItem(USER_KEY))
    console.log("user saved: " + this.user.username)
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getMerchantUser() {
    if (this.user != undefined) return this.user;
    this.user = JSON.parse(sessionStorage.getItem(USER_KEY));
    return this.user;
  }

  public getUser() {
    if (this.user != undefined) return this.user

    this.user = JSON.parse(sessionStorage.getItem(USER_KEY))
    return this.user
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthService} from "../service/auth.service";
import {TokenStorageService} from "../service/tokenStorage.service";


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private _router: Router,
                private _authenticationService: AuthService,
                private _tokenStorage: TokenStorageService) {}

    // canActivate
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // const currentUser  = this._authenticationService.currentUser;

        // console.log("current user email: " + this._tokenStorage.getUser()?.username);

        if (this._tokenStorage.getToken()) {
            return true
        }

        this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
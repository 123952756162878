export const locale = {
  lang: 'en',
  data: {
    menu: {
      dashboard: 'Dashboard',
      statistics: 'Statistics',
      analytics: 'Analytics',
      users: 'Users',
      merchants: 'Merchants',
      orders:'Orders',
      invoices: 'Invoices',
      transactions: 'Transactions',
      settings: 'Settings',
      signOut: 'Sign out',
      call: 'Callz.me',
      calls: 'Calls',
      payments: 'Payments',
      support: 'Support'
    },
  },
};

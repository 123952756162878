import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';

import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { SampleComponent } from './sample.component';
import { HomeComponent } from './home.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from '../../auth/helpers';

const routes = [
  /*{
    path: '',
    component: HomeComponent,
    data: { animation: 'home' },
    canActivate: [AuthGuard]
  },*/
  {
    path: 'sample',
    component: SampleComponent,
    data: { animation: 'sample' },
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { animation: 'home' },
    canActivate: [AuthGuard],
  },
];

@NgModule({
  declarations: [SampleComponent, HomeComponent],
  imports: [RouterModule.forChild(routes), ContentHeaderModule, TranslateModule, CoreCommonModule, NgbNavModule],
  exports: [SampleComponent, HomeComponent],
})
export class SampleModule {}

import { Injectable } from '@angular/core';
import { TokenStorageService } from '../auth/service/tokenStorage.service';
import { User } from '../auth/models';
import { RolesService } from '../auth/service/roles.service';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(private _tokenStorage: TokenStorageService, private _rolesService: RolesService) {}

  getMenu() {
    let user: User = this._tokenStorage.getUser();

        if (!user) {
            return []
        }

        let menu = []
        menu.push(this.dashboard)

        if (this._rolesService.hasAnyOfRoles(user,[this._rolesService.ROLE_ADMIN, this._rolesService.ROLE_OWNER])) {
            menu.push(this.users)
        }

        menu.push(
                this.call,
                this.merchants,
                this.orders,
                this.invoices_main,
                this.settings,
                this.signout
        )

    return menu;
  }

    dashboard = {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'menu.dashboard',
        type: 'collapsible',
        icon: 'trending-up',
        children: [
            {
                id: 'statistics',
                title: 'Statistics',
                translate: 'menu.statistics',
                type: 'item',
                icon: 'activity',
                classes: 'menu-item-inner',
                url: 'statistics',
            },
            {
                id: 'analytics',
                title: 'Analytics',
                translate: 'menu.analytics',
                type: 'item',
                icon: 'bar-chart',
                classes: 'menu-item-inner',
                url: 'analytics',
            },
            {
                id: 'salespersons',
                title: 'Salespersons',
                translate: 'Salespersons',
                type: 'item',
                icon: 'user-check',
                classes: 'menu-item-inner',
                url: 'salespersons-overview',
            },
        ],
    };

  users = {
    id: 'users',
    title: 'Users',
    translate: 'menu.users',
    type: 'item',
    icon: 'users',
    url: 'users',
  };

  merchants = {
    id: 'merchants',
    title: 'Merchants',
    translate: 'menu.merchants',
    type: 'item',
    icon: 'grid',
    url: 'merchants',
  };

  orders = {
        id: 'orders',
        type: 'item',
        title: 'Orders',
        translate: 'menu.orders',
        icon: 'shopping-bag',
        url: 'orders',
    };

  invoices_main = {
    id: 'invoices-main',
    title: 'Invoices',
    translate: 'menu.invoices',
    type: 'collapsible',
    icon: 'paperclip',
    children: [
      {
        id: 'invoices',
        title: 'Invoices',
        translate: 'menu.invoices',
        type: 'item',
        icon: 'file-text',
        classes: 'menu-item-inner',
        url: 'invoices',
      },
      {
        id: 'transactions',
        title: 'Transactions',
        translate: 'menu.transactions',
        type: 'item',
        icon: 'file-plus',
        classes: 'menu-item-inner',
        url: 'transactions',
      },
    ],
  };


  call = {
      id: 'call',
      title: 'Call',
      translate: 'menu.call',
      type: 'collapsible',
      icon: 'trending-up',
      children: [
          {
              id: 'payments',
              title: 'Payments',
              translate: 'menu.payments',
              type: 'item',
              icon: 'activity',
              classes: 'menu-item-inner',
              url: 'payments',
          },
          {
              id: 'calls',
              title: 'Calls',
              translate: 'menu.calls',
              type: 'item',
              icon: 'bar-chart',
              classes: 'menu-item-inner',
              url: 'calls',
          },
          {
              id: 'support',
              title: 'support',
              translate: 'menu.support',
              type: 'item',
              icon: 'user-check',
              classes: 'menu-item-inner',
              url: 'support',
          },
      ],
  };

  settings = {
    id: 'settings',
    title: 'Settings',
    translate: 'menu.settings',
    type: 'item',
    icon: 'settings',
    url: 'settings',
  };

  signout = {
    id: 'signOut',
    title: 'Sign out',
    translate: 'menu.signOut',
    type: 'item',
    icon: 'power',
    url: 'signout',
  };
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChartGroupBy, FilterPage, MerchantBankDetails, MerchantUpdateItem, PaginatePage } from '../../shared/types';
import { User } from '../models';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  public currentUser: Observable<User>;
  public apiUrl: string;

  constructor(private http: HttpClient) {
    this.currentUser = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser'))).asObservable();
    this.apiUrl = environment.apiUrl;
  }

  login(credentials): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/login`,
      {
        email: credentials.email,
        password: credentials.password,
      },
      httpOptions
    );
  }

  getUsers(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/usersadmin/users`, {});
  }

  getAdminRelatedRoles(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/usersadmin/adminRelatedRoles`, {}).toPromise();
  }

  addUser(firstName: string, lastName: string, email: string, password: string, roles: string[], status: boolean): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/internal/usersadmin/users/`,
      {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        roles: roles,
        status: status ? 'ENABLED' : 'DISABLED',
      },
      httpOptions
    );
  }

  getUser(userId: number): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/usersadmin/users`,
      {
        id: Number(userId),
      },
      httpOptions
    );
  }

  changePasswordDetails(credentials): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/user/changePassword`,
      {
        oldPassword: credentials.oldPassword,
        newPassword: credentials.newPassword,
        newPasswordRepeat: credentials.newPasswordRepeat,
      },
      httpOptions
    );
  }

  changeUserAdminPassword(adminId: number, password: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/usersadmin/user/changePassword`,
      {
        id: adminId,
        password: password,
      },
      httpOptions
    );
  }

  changeUserAdminDetails(
    adminId: number,
    firstName: string,
    lastName: string,
    email: string,
    status: string,
    roles: string[]
  ): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/usersadmin/user/`,
      {
        id: Number(adminId),
        firstName: firstName,
        lastName: lastName,
        email: email,
        roles: roles,
        status: status,
      },
      httpOptions
    );
  }

  changeUserAdminStatus(adminId: number, status: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/usersadmin/user/status`,
      {
        id: Number(adminId),
        status: status,
      },
      httpOptions
    );
  }

  deleteUserAdmin(adminId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/internal/usersadmin/users/${adminId}`, httpOptions);
  }

  getOrder(orderId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/shoppingOrders/${orderId}`, {});
  }

  resendEmailOrder(orderId): Observable<any> {
    return this.http.post(`${this.apiUrl}/internal/shoppingOrders/${orderId}/resendEmail`, {}, httpOptions);
  }

  getOrdersFilter(page: PaginatePage, filter: FilterPage[]): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/shoppingOrders/filter?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`,
      {
        filter: filter,
      }
    );
  }

  getOrders(page: PaginatePage): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/internal/shoppingOrders?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`,
      {}
    );
  }

  getDownloadOrder(orderId): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get<any>(`${this.apiUrl}/internal/shoppingOrders/${orderId}/download`, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  getMsaVersion(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/msa/version`, {});
  }

  saveMsaVersion(version: string): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/msa/version`,
      {
        version: version,
      },
      httpOptions
    );
  }

  getMerchantsFilter(page: PaginatePage, filter: FilterPage[]): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/merchants/filter/?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`,
      {
        filter: filter,
      },
      httpOptions
    );
  }

  getMerchants(page: PaginatePage): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/internal/merchants/?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`,
      {}
    );
  }

  updateMerchant(merchant: MerchantUpdateItem): Observable<any> {
    return this.http.post(`${this.apiUrl}/internal/merchants/update`, merchant, httpOptions);
  }

  deleteMerchant(merchantId: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/internal/merchants/${merchantId}`, httpOptions);
  }

  getMerchantDetails(merchantId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/${merchantId}`);
  }

  getMerchantStores(merchantId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/${merchantId}/stores`);
  }

  getMerchantSalesTeam(merchantId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/${merchantId}/salesTeam`);
  }

  getSalespersonsOverview(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/salespersonOverview`);
  }

  saveMerchantBankDetails(merchantId: number, bankDetails: MerchantBankDetails): Observable<any> {
    return this.http.post(`${this.apiUrl}/internal/merchants/${merchantId}/bankDetails`, bankDetails, httpOptions);
  }

  changeMerchantStatus(merchantId: number, newStatus: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/internal/merchants/${merchantId}/changeStatus`, { status: newStatus }, httpOptions);
  }

  saveMerchantStripeId(merchantId: number, stripeId): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/merchants/${merchantId}/stripeId`,
      {
        stripeId: stripeId,
      },
      httpOptions
    );
  }

  saveMerchantResponsibleSaleperson(merchantId: number, userId: number): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/merchants/${merchantId}/salesManager`,
      {
        id: userId,
      },
      httpOptions
    );
  }

  saveMerchantFeeProcessing(merchantId: number, automaticEnabled: boolean, paymentFee: number): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/merchants/${merchantId}/paymentFee`,
      {
        automaticFeeEnabled: automaticEnabled,
        paymentFee: paymentFee,
      },
      httpOptions
    );
  }

  getFeeHistory(merchantId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/${merchantId}/feeHistory`);
  }

  newValidationLink(merchantId: number): Observable<any> {
    return this.http.post(`${this.apiUrl}/internal/merchants/${merchantId}/newValidationLink`, {}, httpOptions);
  }

  register(credentials): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/merchant/register`,
      {
        email: credentials.email,
        password: credentials.password,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        companyName: credentials.companyName,
        country: credentials.country,
        countryCode: credentials.countryCode,
        phoneNumber: credentials.phoneNumber,
        street: credentials.street,
        city: credentials.city,
        postalCode: credentials.postalCode,
        registrationId: credentials.registrationId,
        taxId: credentials.taxId,
        vatId: credentials.vatId,
      },
      httpOptions
    );
  }

  logout() {
    window.sessionStorage.clear();
  }

  /*changeCallStatus(available: boolean) {
      this.http.post<any>(`${this.apiUrl}/merchant/auth/saveCallStatus`, { available: available }).subscribe({
        error: (error) => {
          console.error('Post request failure', error.message);
        },
      });
    }*/

  /*getShippingMethodsList(): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/merchant/auth/shippingOptions`, {});
    }*/

  /*createShippingMethod(name, price: number): Observable<any> {
      return this.http.post(
        `${this.apiUrl}/merchant/auth/shippingOptions`,
        {
          name: name,
          price: price,
        },
        httpOptions
      );
    }

    getShippingMethod(shippingId): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/merchant/auth/shippingOptions/${shippingId}`, {});
    }

    changeShippingMethod(shippingId: number, name, price: number): Observable<any> {
      return this.http.put(
        `${this.apiUrl}/merchant/auth/shippingOptions/${shippingId}`,
        {
          id: shippingId,
          name: name,
          price: price,
        },
        httpOptions
      );
    }

    deleteShippingMethod(shippingId): Observable<any> {
      return this.http.delete(`${this.apiUrl}/merchant/auth/shippingOptions/${shippingId}`, httpOptions);
    }
  */
  /**/

  /*

    updateOrderStatus(orderId, status): Observable<any> {
      return this.http.post(`${this.apiUrl}/merchant/auth/shoppingOrders/${orderId}/status/${status}`, {}, httpOptions);
    }

    updateOrderBillingAddress(orderId, billingAddress): Observable<any> {
      return this.http.post(`${this.apiUrl}/merchant/auth/shoppingOrders/${orderId}/billingAddress/`, billingAddress, httpOptions);
    }

    updateOrderShippingAddress(orderId, shippingAddress): Observable<any> {
      return this.http.post(`${this.apiUrl}/merchant/auth/shoppingOrders/${orderId}/shippingAddress/`, shippingAddress, httpOptions);
    }

    updateOrderNote(orderId, data: { note: string }): Observable<any> {
      return this.http.post(`${this.apiUrl}/merchant/auth/shoppingOrders/${orderId}/note/`, data, httpOptions);
    }

*/

  /* getNewOrders(): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/merchant/auth/shoppingOrders/new/count`);
    }*/

  getTransactions(page: PaginatePage): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/internal/invoicing/transactions?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`
    );
  }

  getTransactionsFilter(page: PaginatePage, filter: FilterPage[]): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/invoicing/transactions?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`,
      {
        filter: filter,
      }
    );
  }

  getInvoices(page: PaginatePage): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/internal/invoicing/invoices?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`
    );
  }

  getInvoicesFilter(page: PaginatePage, filter: FilterPage[]): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/invoicing/filterInvoices?pageNo=${page.pageNumber}&pageSize=${page.size}&sortBy=${page.sortBy}&sortDir=${page.sortDir}`,
      {
        filter: filter,
      }
    );
  }

  getInvoice(invoiceId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/invoicing/invoices/${invoiceId}`, {});
  }

  getDownloadInvoice(invoiceId): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get<any>(`${this.apiUrl}/internal/invoicing/invoices/${invoiceId}/download`, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  updateInvoiceStatus(invoiceId, status): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/internal/invoicing/invoices/changeStatus`,
      {
        invoiceId: invoiceId,
        status: status,
      },
      httpOptions
    );
  }

  /*getUnreadInvoices(): Observable<any> {
      return this.http.get<any>(`${this.apiUrl}/merchant/auth/invoices/count/unread`, {});
    }*/

  getCountryCodes(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/common/registrationCountryCodes`, {});
  }

  getCurrencyMapping(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/common/currencyMapping`, {});
  }

  getMerchantsStatistics(from, to): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/merchantsStats?from=${from}&to=${to}`);
  }

  getTurnoverChart(from, to, groupBy: ChartGroupBy): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/turnoverChart?from=${from}&to=${to}&groupBy=${groupBy}`);
  }

  getCommissionChart(from, to, groupBy: ChartGroupBy): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/commissionChart?from=${from}&to=${to}&groupBy=${groupBy}`);
  }

  getOrderChart(from, to, groupBy: ChartGroupBy): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/orderChart?from=${from}&to=${to}&groupBy=${groupBy}`);
  }

  getCallChart(from, to, groupBy: ChartGroupBy): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/orderChart?from=${from}&to=${to}&groupBy=${groupBy}`);
  }

  getMerchantsAnalytics(from, to): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/merchantsAnalytics?from=${from}&to=${to}`);
  }

  getMerchantsAnalyticsFilter(from, to, filter: FilterPage[]): Observable<any> {
    return this.http.post(`${this.apiUrl}/internal/stats/basic/merchantsAnalytics?from=${from}&to=${to}`, {
      filter: filter,
    });
  }

  getCallStatisticsForMerchant(merchantId: number, from, to): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/merchant/${merchantId}/calls?from=${from}&to=${to}`);
  }

  getOrderStatisticsForMerchant(merchantId: number, from, to): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/internal/stats/basic/merchant/${merchantId}/orders?from=${from}&to=${to}`);
  }

  getTurnoverChartForMerchant(merchantId: number, from, to, groupBy: ChartGroupBy): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/internal/stats/basic/merchant/${merchantId}/turnoverChart?from=${from}&to=${to}&groupBy=${groupBy}`
    );
  }

  getOrderChartForMerchant(merchantId: number, from, to, groupBy: ChartGroupBy): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/internal/stats/basic/merchant/${merchantId}/orderChart?from=${from}&to=${to}&groupBy=${groupBy}`
    );
  }

  getCallChartForMerchant(merchantId: number, from, to, groupBy: ChartGroupBy): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/internal/stats/basic/merchant/${merchantId}/callChart?from=${from}&to=${to}&groupBy=${groupBy}`
    );
  }

  getMerchantCompanyName(merchantId: number) {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/${merchantId}/companyName`);
  }

  getWorkingHours(merchantId: number) {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/${merchantId}/workingHours`);
  }

  getCalls() {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/calls`);
  }

  getPayments() {
    return this.http.get<any>(`${this.apiUrl}/internal/merchants/payments`);
  }

  sendTokenEmail(credentials) {
    return this.http.post<any>(`${this.apiUrl}/internal/merchants/sendTokenEmail`,
        {
          url: credentials.url,
          email: credentials.email
        },
        httpOptions);
  }
}

import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {HttpInterceptor,HttpHandler,HttpRequest} from "@angular/common/http";

import {TokenStorageService} from "../service/tokenStorage.service";

const TOKEN_HEADER_KEY = "Authorization";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private token: TokenStorageService) {
    }


    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let authRegister = req;
        const token = this.token.getToken();
        if(token != null) {
            authRegister = req.clone({
                headers: req.headers.set(TOKEN_HEADER_KEY, "Bearer " + token)

            });
        }
        return next.handle(authRegister);
    }
}

export const authInterceptorProviders = [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true}
]

export const locale = {
  lang: 'en',
  data: {
    calls: {
      title: 'Calls',
      id: 'Id',
      start: 'Start',
      end: 'End',
      merchant: 'Merchant',
      txId: 'TX ID',
      memberId: 'Member ID',
      email: 'Email',
      pricePoint: 'Price point'
    },
    payments: {
      title: 'Payments',
      txId: 'TX ID',
      memberId: 'Member ID',
      date: "Date",
      callId: "Call ID",
      pricePoint: 'Price point'
    },
    merchants: {
      tabGeneral: 'General',
      tabKyc: 'KYC',
      tabBankDetails: 'Bank details',
      tabPaymentGate: 'Payment gateway',
      tabFees: 'Set fees',
      tabStores: 'Stores',
      tabSalesTeam: 'Sales team',
      merchantsTabTitle: 'Merchants',
      analytics: 'Analytics',
      storeId: 'Store ID',
      storeName: 'Store Name',
      firstName: 'First name',
      lastName: 'Last name',
      nickName: 'Nickname',
      priority: 'Call priority',
      status:'Status',
      appVersion:'App version',
      general: {
        accountDetails: 'Account Details',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phoneNumber: 'Phone number',
        username: 'Merchant username',
        firstNamePlaceholder: 'John',
        lastNamePlaceholder: 'Lanton',
        emailPlaceholder: 'john@example.com',
        phonePlaceholder: 'Phone number (e.g. +421XXXXXXXXX)',
        usernamePlaceholder: 'John',
        usernamePopover:
          'Username is used to log in to the Merchant Sales Application. This field is mandatory. Minimum is 1 character in this field. Allowed characters are letters and digits. White space is not allowed.',
        onlyLettersError: 'Must contain only letters',
        phoneError: 'Phone number must be valid',
        usernamePatternError: 'Only letters without diacritics or special symbols and digits are allowed',
        usernameWhitespaceError: 'Username contains invalid character white space',
        usernameExistError: 'Username already exists',
        companyDetails: 'Company Details',
        companyName: 'Company name',
        street: 'Street',
        city: 'City',
        postalCode: 'Postal code',
        country: 'Country',
        registrationID: 'Registration ID',
        taxID: 'TAX ID / EIN',
        vatID: 'VAT ID',
        companyNamePlaceholder: 'Zoen.Shop',
        streetPlaceholder: 'Street',
        cityPlaceholder: 'City',
        postalCodePlaceholder: 'Postal code',
        countryPlaceholder: 'Country',
        registrationIDPlaceholder: 'Registration ID',
        taxIDPlaceholder: 'TAX ID / EIN',
        vatIDPlaceholder: 'VAT identification number',
        patternError: 'Invalid character. Only letters, digits, dash, underscore and white spaces are allowed.',
        responsibleSaleperson: 'Responsible Saleperson',
        select: 'Select',
        status: 'Status:,',
        activity: 'Activity:',
        promoCode: 'Promo code',
        website: 'Website',
        websitePlaceholder:'Website',
        websiteError:'Field must contain valid URL address',
        pricePoint:'Price Point',
      },
      bankDetails: {
        bankDetails: 'Bank Details',
        IBAN: 'IBAN',
        currency: 'Currency',
        bankName: 'Bank Name',
        BICSWIFT: 'BIC/SWIFT',
        countryOfBank: 'Country of the bank',
        IBANPlaceholder: 'IBAN',
        bankNamePlaceholder: 'Bank Name',
        BICSWIFTPlaceholder: 'BIC/SWIFT',
        countryOfBankPlaceholder: 'Your Country',
        businessAccountCheck:
          'I confirm that this bank account is maintained for the company I register and is used for business purposes of the registered.',
        IBANError: 'IBAN must be valid',
        bankNameError: 'Bank Name must be valid',
        BICSWIFTError: 'BIC/SWIFT must be valid',
        IBANCalculator: 'IBAN calculator',
        businessAccountError: 'It is necessary to mark the check box that the account belongs to you.',
        routingNumber:'Routing number',
        routingNumberPatternError:'Invalid format, field must contain 9 digits',
        accountNumber:'Account number',
        accountNumberPatternError:'Invalid format, field must contain 8 -12 digits',
      },
      paymentGateway: {
        stripeId: 'Stripe ID',
      },
      fees: {
        current:"Current fee (%)",
        invalidFeeRange: "Fee must be between 0% and 100%",
        automatically:"Automatically",
        manually:"Manually",
        period:"Period",
        feeForPeriod:"Fee in %",
        history:"Fee history",
      },
      stores : {
        storeId: 'Store ID',
        storeName: 'Store Name',
        shop_via_link: 'Shop via link',
      }
    },
    mt: {
      title: 'Merchants',
      id: 'Id',
      join: 'Join date',
      activity: 'Activity',
      companyName: 'Company name',
      name: 'Name and email',
      address: 'Address',
      regId: 'Reg/Tax/VAT id',
      taxID: 'TAX ID / EIN',
      vatId: 'VAT id',
      phoneNum: 'Phone Nr',
      status: 'Status',
      actions: 'Actions',
      edit: 'Edit',
      changeStatus: 'Change status',
      archive: 'Archive',
      selectedActivityPlaceholder: 'Select activity',
      selectedStatusPlaceholder: 'Select status',
      INACTIVE: 'Inactive',
      ACTIVE: 'Active',
      analytics: 'Analytics',
      activeTooltip: 'Merchant is using Merchant and Sales app and did sales in last 30 days',
      inactiveTooltip: 'Merchant didn’t make any order within last 30 days',
      statusNewTT: 'Merchant didn’t verify his email address yet',
      statusVerifiedTT: 'Merchant verified his email address via link sent him to email after creating account',
      statusPendingTT:
        'documents needed for the KYC process were sent to ZS. Now the documents are checked in ZS, and the merchant is waiting for final approval. Merchant can Sign in to the Merchant app and use it, but the script doesn’t work yet (cannot make a call)',
      statusApprovedTT: 'Merchant has full accecss to the whole ZS system',
      statusRejectedTT:
        'Rejected by Admin from the Admin app. Merchant cannot log in to the Merchant app and cannot make calls',
      statusBlockedTT: 'Blocked by Admin from the Admin App. Merchant can Sign In into Merchant App, but cannot make a call',
      statusDisabledTT: 'Disabled by Admin from the Admin App. Merchant cannot log in to the Merchant App and cannot make calls',
      statusArchivedTT:
        'Merchant or ZS decide to end the cooperation. The merchant cannot be deleted due to data retention for a specified period of time. Merchant will only be archived. Merchant will not be able to Sign In into Merchant and Sales App. Script will not work anymore.',
      delete:'Delete',
      deleteTitle:"Delete this merchant?",
      verificationEmail:"Verification email"
    },
    minversion: {
      title: 'Minimal supported version',
      fieldLabel: 'Minimal supported version',
    },
    adduser: {
      title: 'Add User',
      firstName: 'First name',
      firstNamePlaceholder: 'John',
      lastName: 'Last name',
      lastNamePlaceholder: 'Doe',
      email: 'Email (Username)',
      emailPlaceholder: '',
      password: 'Password',
      passwordPlaceholder: '',
      roles: 'Roles',
      status: 'Status',
      enable: 'Enable',
      disable: 'Disable',
      emailAlreadyExists: 'Email already exists',
      atLeastOneRoleRequired: 'Select at least one role',
    },
    edituser: {
      title: 'Edit',
      changePassword: 'Change password',
      passwordChangedMessageTitle: 'Password changed',
      passwordChangedMessageText: 'Your password has been changed',
    },
    deleteuser: {
      title: 'Delete this user?',
      userLabel: 'User',
      emailLabel: 'Email',
    },
    users: {
      panelTitle: 'Users',
      addUser: 'Add User',
      columnUser: 'User',
      columnEmail: 'Email(Username)',
      columnRoles: 'Roles',
      columnStatus: 'Status',
      menuItemEdit: 'Edit',
      menuItemDelete: 'Delete',
      menuItemChangeStatus: 'Change status',
      menuItemChangePassword: 'Change password',
      selectRolePlaceholder: 'Select role',
      selectStatusPlaceholder: 'Select status',
      searchPlaceholder: 'Search',
    },
    navbar: {
      settings: 'Settings',
      signOut: 'Sign Out',
      changePassword: 'Change Password',
    },
    footer: {
      allRightsReserved: 'All rights Reserved',
    },
    common: {
      search: 'Search',
      actions: 'Actions',
      edit: 'Edit',
      add: 'Add',
      delete: 'Delete',
      saveChanges: 'Save changes',
      save: 'Save',
      cancel: 'Cancel',
      print: 'Print',
      view: 'View',
      requireError: 'This field is required',
      show: 'Show',
      entries: 'entries',
      yes: 'Yes',
      no: 'No',
      warning: 'Warning',
      chooseFile: 'Choose file',
      browse: 'Browse',
      onlyLettersError: 'Must contain only letters',
      invalidEmailError: 'Email must be valid',
      passwordMinError: 'Password must contain at least 6 characters',
      downloadPDF: 'Download PDF',
      detail: 'Detail',
      enabled: 'Enabled',
      disabled: 'Disabled',
      days : {
        mo: 'Monday',
        tu: 'Tuesday',
        we: 'Wednesday',
        th: 'Thursday',
        fr: 'Friday',
        sa: 'Saturday',
        su: 'Sunday',
      }
    },
    statuses: {
      new: 'New',
      inProgress: 'In progress',
      completed: 'Completed',
      cancelled: 'Cancelled',
      PAID: 'Paid',
      UNPAID: 'Unpaid',
      SETTLEMENT: 'SETTLEMENT',
      WIRE_TRANSFER: 'Wire transfer',
      REFUND: 'Refund',
      REFUND_FEE: 'Refund fee',
      CHARGEBACK: 'Chargeback',
      CHARGEBACK_FEE: 'Chargeback fee',
      ZSFEE: 'ZS fee',
      STRIPE_FEE: 'Stripe fee',
      PAYMENT: 'Payment',
    },
    messages: {
      info: 'Info',
      cancelText: 'If there were any, your changes are reverted.',
      error: 'Error!',
      errorText: 'Ups, something went wrong while saving your changes. We are working on it.',
      success: 'Success',
      successText: 'Your changes have been saved.',
      changeSuccessText: 'Your data changed successfully.',
      unexpectedError: 'There is some unexpected error, please try it later.',
      passwordSave: 'Your password has been changed successfully.',
      invalidErrorText: 'Invalid forms input',
      importError: 'Invalid data format. File could not be imported.',
      warning: 'Warning',
      warningImportText:
        ' {{successfullyImportedSize}} records from {{ allSize }} have been imported successfully. Some of your records were not processed. Error in line {{ lineErrors }}.',
      successImportText: 'Your file with {{ successfullyImportedSize }} records has been imported successfully.',
      successSendEmail: 'Email has been sent successfully.',
      successCopy: 'Copied sucessfully',
    },
    login: {
      topText: 'Please log in to your account',
      email: 'Email',
      password: 'Password',
      emailPlaceholder: 'john@example.com',
      emailRequiredError: 'Email is required',
      emailValidError: 'Email must be a valid email address',
      passwordRequiredError: 'Password is required',
      forgotPassword: 'Forgot Password',
      rememberMe: 'Remember Me',
      loginBtn: 'Log in',
      newOnOurPlatform: 'New on our platform',
      createAccount: 'Create an account',
      appTitle: 'Administration Application',
    },
    forgotPassword: {
      title: 'Forgot Password?',
      text: "Enter your email and we'll send you instructions to reset your password",
      email: 'Email',
      emailPlaceholder: 'john@example.com',
      emailValidError: 'Must be a valid email',
      btnText: 'Send reset link',
      backToLogin: 'Back to login',
    },
    register: {
      title: 'Create your zoen.shop account',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      password: 'Password',
      companyName: 'Company name',
      country: 'Country',
      firstNamePlaceholder: 'John',
      lastNamePlaceholder: 'Lanton',
      emailPlaceholder: 'john@example.com',
      companyNamePlaceholder: 'zoen.shop',
      countryPlaceholder: 'Your Country',
      onlyLettersError: 'Must contain only letters',
      emailValidError: 'Must be a valid email',
      passwordMinError: 'Password must contain at least 6 characters',
      agreeTo: 'By registering this account, I agree to',
      privacyPolicy: 'Privacy Policy',
      termsAndConditions: 'Terms and Conditions',
      stripe: 'Stripe Connected Account Agreement',
      privacyPolicyTermsError: 'You must agree to privacy policy & terms',
      registerBtn: 'Sign up',
      alreadyHaveAccount: 'Already have an account?',
      logIn: 'Log in instead',
    },
    verification: {
      title: 'Woohoo, your email is verified!',
      text: 'Now you can access your zoen.shop account',
      logIn: 'Log in',
      verificationLinkExpired: 'Verification link expired',
      sendNewVerificationEmail: 'Send new verification email to',
      emailRequiredError: 'Email is required',
      emailValidError: 'Email must be a valid email address',
      btnText: 'SEND',
    },
    infoMessage: {
      hello: 'Hello',
      text: 'Thank you for signing up to zoen.shop! In order to get started we need to verify your email address.<br><br><b>Please check your email, including Promotions/Ads and Spam folder, and click the link to verify your email address.</b><br><br>Your zoen.shop team',
    },
    stores: {
      title: 'Stores',
      addStore: 'Add Store',
      store: 'Store',
      pin: 'PIN',
      integrationScript: 'Integration script',
      modalScriptTitle: 'Store integration script',
      modalScriptText: 'Copy this script into <body> section of your website',
      copy: 'Copy',
      storeName: 'Store name',
      storeExistError: 'Store with such name already exists',
      storePin: 'Store PIN',
      storePinPopover: 'PIN used to login in zoen.shop sales application. PIN has to contain 4 digits and has to be unique.',
      storePinPatternError: 'PIN has to contain 4 digits and has to be unique',
      storePinExistError: 'PIN must be unique',
      tabGeneral: 'General',
      storeDetails: 'Store details',
    },
    salesTeam: {
      title: 'Sales Team',
      addSalesperson: 'Add Salesperson',
      salesPerson: 'Sales person',
      name: 'Name',
      nickname: 'Nickname',
      priority: 'Call priority',
      pin: 'PIN',
      firstName: 'First name',
      lastName: 'Last name',
      pinPopoverTitle: 'Salesperson PIN',
      pinPopover: 'PIN used to log in zoen shop sales application. Pin has to contain 4 digits and has to be unique.',
      pinPatternError: 'PIN has to contain 4 digits and has to be unique',
      pinExistError: 'Salesperson PIN must be unique in the scope of Sales Team',
      tabGeneral: 'General',
      salespersonDetails: 'Salesperson details',
      id:'Salesperson Id'
    },
    goods: {
      title: 'Goods',
      popover:
        'This section allows you to manage your goods. Add, change, delete individual items individually, as well as import goods from an external source using the CSV file.',
      addNewItem: 'Add new item',
      importCSV: 'Import CSV',
      importPopover:
        'Importing a CSV file allows you to add items in bulk from external source. E.g. from the stock program in a defined format.',
      productID: 'Product ID',
      item: 'Item',
      price: 'price',
      deleteModalTitle: 'Delete this item?',
      idPopover: 'Internal ID used to identifies a specific item of goods.',
      itemPopover: 'The name of a specific item of goods.',
      pricePopover: 'Final price with VAT.',
      productExistError: 'This product id already exists',
      itemPatternError: 'Incorrect field format.',
      pricePatternError: 'Incorrect field format.',
      priceMinError: 'The amount must be greater than zero.',
      addGoods: 'Add Goods',
      warningText: 'An item with this ID already exists. Do you want to overwrite it?',
      importFile: 'Import file',
      importText:
        'Importing a CSV file allows you to add items in bulk from an external source. E.g. from the stock program in a defined format.<br />A comma-separated values (CSV) file is a delimited text file that uses a comma to separate values. Each line of the file is a data record. Each record consists of one or more fields, separated by commas. The use of the comma as a field separator is the source of the name for this file format.<br /><br /> First row of the file will be header and is mandatory. The header will not be considered when importing. This line will not be checked and imported.The file header is for your convenience only, to indicate the table column names.<br /><br /><br /> Each row will contain 3 values. ID, Item, Price in this order.<br />Values ID, Item and Price are mandatory. Minimum is 1 character in these fields.<br /><br /> For ID field :<br /> Merchant’s internal ID of item (e.g. 158CTL542ae). Allowed characters are letters, digits and symbols.<br /><br /> For Item field:<br /> Name of item goods. Allowed characters are letters, digits and symbols. Name must be with quotation marks as first and last character of Item.Within the name quotation marks are not allowed (e.g. “Woman black T-shirt”, wrong record - “Woman “black T-shirt”)<br /><br /> For Price field:<br /> Value must be > 0. An amount of two decimal places and without decimal places is allowed. Only a dot is allowed as a decimal point. (e.g. 124.70 or 124 both are correct)<br /><br /> Example of a correct entry:<br /> 158CTL542ae, “Woman black T-shirt”,124.70<br />',
      importResult:
        '{{ successfullyImportedSize }} records from {{ allSize }} have been imported successfully. Some of your records were not processed. Error in line {{ lineErrors }}.',
    },
    orders: {
      title: 'Orders',
      order: 'Order',
      searchByIdPlaceholder: 'Order Id',
      selectStatus: 'Select status',
      orderNr: '# Order Nr',
      status: 'Status',
      merchant: 'Merchant name and email',
      client: 'Shopper name and email',
      total: 'Total',
      orderedDate: 'Ordered date',
      paymentStatus: 'Payment status',
      changeStatus: 'Change status',
      resendEmail: 'Resend email',
      resendEmailModalText:
        'Are you sure you want to resend the email with the contents of the <b>Order Nr.{{ orderId }}</b> to <b>{{ customerEmail }}</b>?',
      orderStatus: 'Order status',
      dateOfOrder: 'Date of order',
      product: 'Product',
      quantity: 'Quantity',
      unitPrice: 'Unit price',
      price: 'Price',
      providedDiscount: 'Provided discount',
      subtotal: 'Subtotal',
      shipping: 'Shipping',
      paymentMethod: 'Payment method',
      totalPrice: 'Total price',
      note: 'Note',
      billingAddress: 'Billing address',
      shippingAddress: 'Shipping address',
      email: 'Email',
      mobile: 'Mobile',
      firstName: 'First Name',
      lastName: 'Last Name',
      street: 'Street',
      city: 'City',
      postalCode: 'Postal code',
      country: 'Country',
      phoneNumber: 'Phone number',
      onlyLettersError: 'Must contain only letters',
      emailError: 'Email must be valid',
    },
    invoices: {
      title: 'Invoices',
      selectStatus: 'Select status',
      changeStatus: 'Change status',
      invoice: 'Invoice',
      invoiceNr: 'Invoice Nr.',
      merchantName: 'Merchant name',
      issuedDate: 'Issued date',
      dueDate: 'Due date',
      total: 'Total',
      status: 'Status',
      purchaser: 'Purchaser',
      dateOfIssue: 'Date of issue',
      supplyOfService: 'Supply of service',
      regNo: 'Reg.no.',
      taxNo: 'Tax no.',
      vatNo: 'VAT no.',
      variableSymbol: 'Variable symbol',
      typeOfPayment: 'Type of payment',
      accountNumber: 'Account number',
      totalAmountToPay: 'Total amount to pay',
      periodText: 'We are invoicing you for provided services',
      period: 'Period',
      service: 'Service',
      countOfTransactions: 'Count of transactions',
      amountWithoutVAT: 'Amount without VAT',
      vat: 'VAT',
      totalAmount: 'Total amount',
      baseOfVAT: 'Base of VAT',
      vatTransfer: 'VAT transfer',
      notIncludingVAT: 'Not including VAT',
      IBAN: 'IBAN',
      BIC: 'BIC',
      PAID: 'PAID!',
    },
    changePassword: {
      title: 'Change password',
      oldPassword: 'Old password',
      noMatch: 'Passwords do not match',
    },
    transactions: {
      title: 'Transactions',
      exportCSV: 'Export CSV',
      selectTransactionType: 'Select transaction type',
      orderID: 'Order ID',
      shopperName: 'Shopper name',
      merchantName: 'Merchant name',
      transactionDate: 'Transaction date',
      transactionType: 'Transaction type',
      amount: 'Amount',
      fee: 'Fee',
      netAmount: 'Net amount',
      availableOn: 'Available on',
      payoutDate: 'Payout date',
      invoiceNr: 'Invoice Nr.',
    },
    accountSettings: {
      title: 'Account settings',
      tabs: {
        accountDetails: 'Account Details',
        bankDetails: 'Bank Details',
        shipping: 'Shipping',
        changePassword: 'Change Password',
      },
      accountDetails: {
        accountDetails: 'Account Details',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phoneNumber: 'Phone number',
        username: 'Username',
        firstNamePlaceholder: 'John',
        lastNamePlaceholder: 'Lanton',
        emailPlaceholder: 'john@example.com',
        phonePlaceholder: 'Phone number (e.g. +421XXXXXXXXX)',
        usernamePlaceholder: 'John',
        usernamePopover:
          'Username is used to log in to the Merchant Sales Application. This field is mandatory. Minimum is 1 character in this field. Allowed characters are letters and digits. White space is not allowed.',
        onlyLettersError: 'Must contain only letters',
        phoneError: 'Phone number must be valid',
        usernamePatternError: 'Only letters without diacritics or special symbols and digits are allowed',
        usernameWhitespaceError: 'Username contains invalid character white space',
        usernameExistError: 'Username already exists',
        companyDetails: 'Company Details',
        companyName: 'Company name',
        street: 'Street',
        city: 'City',
        postalCode: 'Postal code',
        country: 'Country',
        registrationID: 'Registration ID',
        taxID: 'TAX ID',
        vatID: 'VAT ID',
        companyNamePlaceholder: 'Zoen.Shop',
        streetPlaceholder: 'Street',
        cityPlaceholder: 'City',
        postalCodePlaceholder: 'Postal code',
        countryPlaceholder: 'Country',
        registrationIDPlaceholder: 'Registration ID',
        taxIDPlaceholder: 'TAX ID',
        vatIDPlaceholder: 'VAT identification number',
        patternError: 'Invalid character. Only letters, digits, dash, underscore and white spaces are allowed.',
      },
      bankDetails: {
        bankDetails: 'Bank Details',
        IBAN: 'IBAN',
        currency: 'Currency',
        bankName: 'Bank Name',
        BICSWIFT: 'BIC/SWIFT',
        countryOfBank: 'Country of the bank',
        IBANPlaceholder: 'IBAN',
        bankNamePlaceholder: 'Bank Name',
        BICSWIFTPlaceholder: 'BIC/SWIFT',
        countryOfBankPlaceholder: 'Your Country',
        businessAccountCheck:
          'I confirm that this bank account is maintained for the company I register and is used for business purposes of the registered.',
        IBANError: 'IBAN must be valid',
        bankNameError: 'Bank Name must be valid',
        BICSWIFTError: 'BIC/SWIFT must be valid',
        IBANCalculator: 'IBAN calculator',
        businessAccountError: 'It is necessary to mark the check box that the account belongs to you.',
      },
      changePassword: {
        changePassword: 'Change Password',
        oldPassword: 'Old password',
        newPassword: 'New password',
        retypeNewPassword: 'Retype new password',
        passwordMinError: 'Password must contain at least 6 characters',
        passwordIncorrectError: 'The “current” password you entered is incorrect',
        passwordMatchError: 'Passwords do not match',
      },
    },
    shipping: {
      title: 'Shipping methods',
      addShipping: 'Add shipping',
      shipping: 'Shipping',
      price: 'Price',
      deleteModalTitle: 'Delete this item?',
      shippingMethod: 'Shipping method',
      shippingExistError: 'Shipping method with such name already exists',
      pricePatternError: 'Incorrect field format',
      priceMinError: 'The amount must be greater than zero.',
      general: 'General',
      editShippingMethod: 'Edit Shipping method',
    },
    dashboard: {
      statistics: 'Statistics',
      turnover: 'Turnover',
      commissions: 'Commissions',
      providerFee: 'Provider fee',
      netCommission: 'Net Commission',
      calls: 'Calls',
      minutes: 'Minutes',
      orders: 'Orders',
      merchantsWithOrders: 'Merchants with orders',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      today: 'Today',
      last7days: 'Last 7 days',
      last30days: 'Last 30 days',
      customRange: 'Custom range',
      to: 'to',
      analytics: 'Analytics',
      numberOfCalls: 'Number Of Calls',
      commission: 'Commission',
      merchant: 'Merchant',
      products: 'Products',
      revenue: 'Revenue',
      highestOrderAmount: 'Highest order amount',
      lowestOrderAmount: 'Lowest order amount',
      callsWithOrder: 'Calls with order',
      callsWithoutOrder: 'Calls without order',
      avarageTalkTimePerOrder: 'Avarage talk time per order',
      avarageOrderValuePerCall: 'Avarage order value per call',
      percentSuccessCallToOrder: '% succes - call to order',
      turnoverPerMinute: 'Turnover per minute',
      averageOrderPrice: 'Average order price',
      averageProductsPerOrder: 'Average products per order',
      missedCalls: 'Missed calls',
      missedCallsPercent: 'Missed calls (%)',
    },
    workingHours: {
      generalTabTitle: 'General working hours',
      enabledMessage: 'Working hours are enabled',
      disabledMessage: 'Working hours are disabled',
      workingHours: 'Working hours',
      day: 'Day',
      tp1: 'Time period 1',
      tp2: 'Time period 2',
    },
    support: {
      title: 'Send link via email',
      url: 'Page (https://...)',
      email: 'Email',
      send: 'Send'
    }
  }
};

import { EventEmitter, Injectable, Output } from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // @Output() updateInvoiceBadgeEvent: EventEmitter<any> = new EventEmitter();
  @Output() collapsedNavMenu: EventEmitter<any> = new EventEmitter();
  messages: any;

  constructor(private toastr: ToastrService, private translate: TranslateService) {
    this.translate.get('messages').subscribe((res: string) => {
      this.messages = res;
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate.get('messages').subscribe((res: string) => {
        this.messages = res;
      });
    });

  }


  displayDefaultErrorMessage() {
    this.toastr.error(this.messages.unexpectedError, this.messages.error, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  displayDefaultSuccessMessage() {
    this.toastr.success(this.messages.changeSuccessText, this.messages.success, {
      toastClass: 'toast ngx-toastr',
      closeButton: true,
    });
  }

  displayDefaultCancelMessage() {
    this.toastr.info(this.messages.cancelText, this.messages.info, {
      toastClass: 'toast ngx-toastr',
      closeButton: true
    });
  }

}

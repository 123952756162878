<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <!-- Basic Alerts start -->
    <section id="home-page">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Kick start your project 🚀</h4>
            </div>
            <div class="card-body pb-1">
              <p class="card-text">All the best for your new project.</p>
              <p class="card-text">
                Please make sure to read our
                <a
                  href="https://pixinvent.com/demo/vuexy-angular-admin-dashboard-template/documentation/"
                  target="_blank"
                  >Template Documentation</a
                >
                to understand where to go from here and how to use our template.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

      <section id="basic-tabs-components">
      <div class="row">
        <div class="col-12">
              hello world
              <ul ngbNav #navWithIcons="ngbNav" class="nav-tabs">
                <li ngbNavItem>
                  <a ngbNavLink><span [data-feather]="'cast'"></span>Home</a>
                  <ng-template ngbNavContent>
                    <p>
                      Candy canes donut chupa chups candy canes lemon drops oat cake wafer. Cotton candy candy canes
                      marzipan carrot cake. Sesame snaps lemon drops candy marzipan donut brownie tootsie roll. Icing
                      croissant bonbon biscuit gummi bears. Pudding candy canes sugar plum cookie chocolate cake powder
                      croissant. Carrot cake tiramisu danish candy cake muffin croissant tart dessert. Tiramisu caramels
                      candy canes chocolate cake sweet roll liquorice icing cupcake. Candy cookie sweet roll bear claw
                      sweet roll.
                    </p>
                  </ng-template>
                </li>
                <li ngbNavItem>
                  <a ngbNavLink>Service</a>
                  <ng-template ngbNavContent>
                    <p>
                      Muffin cupcake candy chocolate cake gummi bears fruitcake donut dessert pie. Wafer toffee bonbon
                      dragée. Jujubes cotton candy gummies chupa chups. Sweet fruitcake cheesecake biscuit cotton candy.
                      Cookie powder marshmallow donut. Candy cookie sweet roll bear claw sweet roll. Cake tiramisu
                      cotton candy gingerbread cheesecake toffee cake. Cookie liquorice dessert candy canes jelly. Sweet
                      chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread
                      jelly beans chocolate bar oat cake wafer. Chocolate bar danish icing sweet apple pie jelly-o
                      carrot cake cookie cake.
                    </p>
                  </ng-template>
                </li>
              </ul>
            </div>
      </div>
      </section>



    <!-- Basic Alerts end -->


    <app-content-header [contentHeader]="contentHeader"></app-content-header>


      </div>
</div>

import { Component, OnInit } from '@angular/core'
import {TabsModule} from "../../../@core/components/tabs/tabs.module";
import {CoreCardSnippetComponent} from "../../../@core/components/card-snippet/card-snippet.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor() {}

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Sample',
            isLink: false
          }
        ]
      }
    }
  }
}
